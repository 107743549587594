$white: #fff;
$black: #0a0a0a;
$default-primary-base-color: #000000;
$default-primary-dark-color: #000000;
$default-primary-light-color: #ffffff;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;
